
$(document).ready(function() {

	$('.collapse_sidebar').click(function() {
		if($('.navbar-collapse').hasClass('sidebar_collapsed')) {
			$('.full_site').removeClass('sidebar_collapsed');
    		$('.navbar-collapse').removeClass('sidebar_collapsed');
    		PhpSessionWrite('sidebar_state', 'full');
    	} else {
    		$('.full_site').addClass('sidebar_collapsed');
    		$('.navbar-collapse').addClass('sidebar_collapsed');
    		PhpSessionWrite('sidebar_state', 'collapsed');
    	}
	});
	
});