
$(document).ready(function() {

	$('.section a.section_titre').click(function() {

        id_section = $(this).parent().attr('id');
        session_name = 'admin_section_'+id_section;

		if($(this).attr('aria-expanded') == 'false') {
            PhpSessionWrite(session_name,'1');
        } else {
            PhpSessionWrite(session_name,'0');
        }

	});
	
});