
$(document).ready(function() {

	$('.hmready').mouseenter(function() {
		help_msg = $(this).attr('help-msg');
		$('.admin_help_msg').html(help_msg);
	});

	$('.hmready').mouseleave(function() {
		help_msg = 'Barre d\'information : survolez un élément pour obtenir de l\'aide sur son fonctionnement';
		$('.admin_help_msg').html(help_msg);
	});



});