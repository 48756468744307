
$(document).ready(function() {

	$('.order_controls div').click(function() {
		
		order_table = $(this).attr('order-table');
		reference_column = $(this).attr('reference-column');
		line_id = $(this).attr('line-id');
		limiting_column = $(this).attr('limiting-column');
		limiting_column_value = $(this).attr('limiting-column-value');

		if($(this).hasClass('order_control_up')) { direction = 'up'; }
		if($(this).hasClass('order_control_down')) { direction = 'down'; }

		$.ajax({
			type: 'GET',
			url: '/admin/php/order_entry.php?order_table='+order_table+'&reference_column='+reference_column+'&line_id='+line_id+'&direction='+direction+'&limiting_column='+limiting_column+'&limiting_column_value='+limiting_column_value,
			success: function(resultat) {
				/*if(debug_mode == true) { 
					console.log('L\'entrée suivante a été supprimée : delete_table = '+delete_table+', delete_column = '+delete_column+', delete_id = '+delete_id); 
				} */
				/*document.location = document.location.href;*/
				reloadScroll();
			},
			error: function() {
				/*if(debug_mode == true) { 
					console.log('Echec lors de la suppression de l\'entrée : delete_table = '+delete_table+', delete_column = '+delete_column+', delete_id = '+delete_id); 
				} */
			}
		});


	});
});