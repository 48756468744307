/*
|--------------------------------------------------------------------------
| Bootstrap 4 - Load Popover Content From DOM
|--------------------------------------------------------------------------
|
| A quick JavaScript snippet that lets you dynamically load the content of
| a Bootstrap 4 popover from another HTML element in the DOM by making use
| of a data-popover-content attribute to reference a selector.
|
*/

$(function(){
    
    $("[data-toggle=popover]").popover({
        html : true,
        content: function() {
            var content = $(this).attr("data-popover-content");
            return $(content).children(".popover-body").html();
        },
        title: function() {
            var title = $(this).attr("data-popover-content");
            return $(title).children(".popover-heading").html();
        }
    });
    
});

$(document).on("click", ".popover-header .close_pop", function() {
    $("[data-toggle=popover]").popover('hide');
});